<template>
  <div>
    <section class="content box-management">
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid">

            <div class="col-xs-12">
              <h2>Ustawienia klientów</h2>
              <div class="col-xs-6">
                <div class="box-body">

                  <input-type
                    v-for="field in schema"
                    :key="field.name"
                    v-model="search"
                    v-validate="{ rules: field.rules }"
                    :data-vv-value-path="'value'"
                    :data-vv-as="field.label2 ? field.label2 : field.label"
                    :error-message="vErrors.first(field.name)"
                    :label="field.label"
                    :name="field.name"
                    :required="field.rules.required"
                  />
                </div>
                <div class="col-xs-12 client-roles-filter">
                  <label for="roles-filter-multiselect">Szukaj ról</label>
                  <VueMultiselect
                    id="roles-filter-multiselect"
                    v-model="rolesFilters"
                    :options="roles"
                    :multiple="true"
                    track-by="id"
                    label="label"
                    placeholder="Wybierz Role do filtrowania"
                    select-label="Zaznacz"
                    selected-label="Zaznaczony"
                    deselect-label="Odznacz"
                  />
                </div>
              </div>
              <div class="box-body">
                <div class="row col-xs-6">
                  <label>Ustaw klienta wszystkim przefiltrowanym
                    użytkownikom</label>
                  <ul>
                    <template v-for="(client) in clients">
                      <li
                        class="col-xs-6 clients-client-checkbox-container"
                        :key="client.id"
                      >
                        <input
                          :id="`input-checkbox-${client.id}`"
                          type="checkbox"
                          @change="(event) => assignUsersToClient({client}, event)"
                        >
                        <label
                          :for="`input-checkbox-${client.id}`"
                        >{{client.name}}</label>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
            <ul class="box-body row">
              <transition-group name="fade">
                <li
                  v-for="(user) in filteredList"
                  :key="user.id"
                  class="list-group-item col-xs-6"
                >
                  <div class="row">
                    <div class="col-sm-4">
                      <label :for="`client-select${user.id}`">{{ user.label
                        }}</label>
                    </div>
                    <div
                      v-if="usersList"
                      class="col-sm-8"
                    >
                      <select
                        :id="`client-select${user.id}`"
                        v-model="usersList[user.id].clients"
                        class="form-control"
                        multiple
                      >
                        <option
                          v-for="(option, optIndex) in clients"
                          :key="optIndex"
                          :value="option.id"
                        >
                          {{ option.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                </li>
              </transition-group>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import api from '../../../../../api'
  import InputType from '../../../../share/form/type/InputType'
  import VueMultiselect from 'vue-multiselect'

  export default {
    components: {
      InputType,
      VueMultiselect
    },
    mixins: [],
    data () {
      return {
        events: null,
        service: this.$route.meta.acl.service,
        clients: [],
        gridKey: 0,
        users: [],
        search: '',
        schema: [
          {
            name: 'search',
            label: 'Szukaj użytkownika',
            rules: { required: false }
          }],
        roles: [],
        rolesFilters: []
      }
    },
    computed: {
      filteredList () {
        if (this.usersList) {
          return this.users.filter(user => user.label &&
            user.label.toLowerCase().includes(this.search.toLowerCase()) &&
            (this.rolesFilters.length === 0 ||
              this.rolesFilters.some(role => user.roles.includes(role.name)))
          )
        } else {
          return []
        }
      },
      usersList () {
        let usersList = {}
        this.users.forEach(user => {
          usersList[user.id] = {
            clients: user.clients.map(client => client.id
            )
          }
        })
        return usersList
      }
    },
    beforeMount () {
      this.getClients()
      this.getRoles()
    },
    created () {
      this.getInitEvents()
    },
    mounted () {
      this.$events.on(this.events.save, this.save)
      this.getUsers()
    },
    methods: {
      getInitEvents () {
        this.events = {
          save: `${this.service}:clients:save`
        }
      },
      async save () {
        try {
          let data = Object.entries(this.usersList).map(element => {
            return { id: element[0], clients: element[1].clients }
          })
          await api.request(this.service, 'put', '/users', data)
          this.$notify({
            type: 'success',
            title: 'Zapisano',
            text: ''
          })
        } catch (e) {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można zapisać'
          })
        }
      },
      async getClients () {
        try {
          let response = await api.request(this.service, 'get', '/clients')
          this.clients = response.data
        } catch (e) {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można pobrać klientów'
          })
        }
      },
      async getRoles () {
        try {
          let response = await api.request(this.service, 'get', '/roles')
          this.roles = response.data
        } catch (e) {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można pobrać ról'
          })
        }
      },
      async getUsers () {
        try {
          let response = await api.request(this.service, 'get',
            '/users?format=admin')
          this.users = response.data.map(user => {
            return {
              id: user.id,
              label: user.label,
              clients: user.clients,
              roles: user.roles
            }
          })
        } catch (e) {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można pobrać użytkowników'
          })
        }
      },
      assignUsersToClient ({ client }, event) {
        this.filteredList.forEach(
          listUser => this.changeClients(listUser, client, event))
      },
      changeClients (listUser, client, event) {
        let user = listUser
        if (user.clients.find(listClient => listClient.id === client.id) &&
          !event.target.checked) {
          user.clients = user.clients.filter(
            listClient => listClient.id !== client.id)
        } else {
          user.clients.push(client)
        }
      }
    }
  }
</script>
<style>
  .client-roles-filter {
    padding: 20px;
  }

  .clients-client-checkbox-container {
    list-style: none;
    padding: 5px;
  }

  .fade-enter-active {
    animation: stretch 0.25s;
  }

  .fade-leave-active {
    animation: stretch 0.25s reverse;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
    height: 1%;
  }

  @keyframes stretch {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
</style>
